import { Link } from "gatsby";
import React from "react";
import ProductImage from "./ProductImage";

export default function MountingTypeThumbnailList({
  mountings,
  productImages,
  // title,
}) {
  if (!mountings || mountings.length < 1) return <div />;

  // sort mountings
  mountings.sort((a, b) =>
    a.data.webDisplayOrder > b.data.webDisplayOrder ? 1 : -1
  );

  return (
    <>
      {/* {title && <h2 className="pb-small fs-xxl">{title}</h2>} */}
      <dl className="mounting-thumbnail-list fs-small">
        {mountings.map((mounting, i) => {
          // Does mounting have T2s
          if (mounting.data.T2 && mounting.data.T2.length < 1) {
            return (
              <React.Fragment key={`mounting-${i}`}>
                <dt className="" key={`${i}-dt`}>
                  {mounting.data.Name}
                </dt>
                <dd className="" key={`${i}-dd`} />
              </React.Fragment>
            );
          }

          const T2s = mounting.data.T2;

          return (
            <React.Fragment key={`mounting-${i}`}>
              {/* <hr className="mb-small" /> */}
              {i > 0 && <hr className="mb-small" />}
              {/* Mounting */}
              <dt className="fs-xxl mb-large c-em" key={`${i}-dt`}>
                {mounting.data.Name}
              </dt>
              <dd className="mb-medium" key={`${i}-dd`}>
                {/* Thumbnails List */}
                <ul className="thumbnail-list grid-fixed-1 grid-fixed-ms-2 grid-fixed-mm-2 grid-fixed-mxl-3 grid-fixed-mxxxl-4 mb-small">
                  {T2s.sort((a, b) =>
                    // a.data.webFullName > b.data.webFullName ? 1 : -1
                    a.data.webFullName.localeCompare(b.data.webFullName, "en", {
                      numeric: true,
                      ignorePunctuation: true,
                    })
                  ).map((T2) => {
                    // If not published, hide
                    if (T2.data.webPublishedResults === null)
                      return (
                        <React.Fragment
                          key={`${mounting.data.Name}-${T2.recordId}`}
                        />
                      );

                    return (
                      <li key={`${mounting.data.Name}-${T2.recordId}`}>
                        <Link
                          to={`/range/${T2.data.webT4Slug}/${T2.data.webSlug}/`}
                          className="thumbnail-container"
                        >
                          {T2.data.webHeroImage &&
                          T2.data.webHeroImage.length > 0 ? (
                            <div className="thumbnail-image">
                              <ProductImage
                                filename={T2.data.webHeroImage[0].data.Filename}
                                alt={`${T2.data.webDisplayNameFirst} ${
                                  T2.data.webDisplayNameSecondShort
                                    ? T2.data.webDisplayNameSecondShort
                                    : ""
                                }`}
                                productImages={productImages}
                              />
                            </div>
                          ) : (
                            <div className="thumbnail-image">
                              <ProductImage placeholder />
                            </div>
                          )}
                          <div className="thumbnail-name px-small pt-small c-em">
                            <span className="fw-medium">
                              {T2.data.webDisplayNameFirst}
                            </span>
                            {T2.data.webDisplayNameSecondShort && (
                              <>
                                <br />
                                <span>{T2.data.webDisplayNameSecondShort}</span>
                              </>
                            )}
                          </div>
                          <div className="thumbnail-attributes mx-small mb-small">
                            <ul className="attributes">
                              {T2.data.webPower && T2.data.webPower.length > 0 && (
                                <li className="">
                                  {/* <span className="label fw-medium">Power</span> */}
                                  <ul className="power">
                                    {T2.data.webPower.sort().map((power, x) => (
                                      <li key={x} className="value fs-tiny">
                                        {power}W
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              )}
                              {T2.data.webFluxRange && (
                                <>
                                  <li className="flux value fs-tiny">
                                    {T2.data.webFluxRange}lm
                                  </li>
                                </>
                              )}
                              {T2.data.webDistribution &&
                                T2.data.webDistribution.length > 0 && (
                                  <>
                                    <li className="hide-ms-only">
                                      <ul className="distribution">
                                        {T2.data.webDistribution.map(
                                          (distribution, x) => (
                                            <li
                                              key={x}
                                              className="value fs-tiny"
                                            >
                                              {distribution}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </li>
                                  </>
                                )}
                            </ul>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </dd>
            </React.Fragment>
          );
        })}
      </dl>
    </>
  );
}
