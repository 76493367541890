import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function ProductImage({
  filename,
  alt,
  productImages,
  placeholder,
}) {
  const placeholderImage = (
    <>
      <StaticImage
        src="../images/placeholder-2000x2000.png"
        alt="Placeholder Image"
      />
    </>
  );

  if (placeholder) return placeholderImage;

  if (!filename || !productImages || productImages.length < 1) {
    return placeholderImage;
  }
  const imageData = productImages.find(
    (x) => x.parent.relativePath === filename
  );

  if (!imageData) {
    return placeholderImage;
  }

  const image = getImage(imageData);

  if (!image) {
    return placeholderImage;
  }
  return <GatsbyImage image={image} alt={alt} />;
}
